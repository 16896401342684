#Home{
    padding-top: 100px ;
    color: rgb(23, 23, 23);
    position: relative;
    top: 10px;
    display: flex;
    padding-bottom: 120px;
    background-color: rgb(255, 255, 255);
}
.pic img{
    max-width: 100%;
    height: auto;
    position: relative;
    transition: 0.6s;
}
.pic img:hover{
    transform: scale(1.03);
}
.intro p{
    font-size: 40px;
    margin-left: 20px;
}
#intro1{
    color: rgb(10, 44, 36);
}
#intro2{
    margin: -40px 0px 0px 20px  ;
    margin-right: -40px;
    font-weight:600;
    font-size: 55px;
    border-right: 8px solid #3e465ab3; /* Adjusted border width for better visibility */
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 2s ,
    cursor .4s step-end infinite alternate;
}
#intro3{
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    text-align: center;
    width: 90%;
}
#intro3 span{
    color: rgb(77, 21, 181);
    font-weight: 600;
}
.icon{
    padding: 4px;
    position: absolute;
    left: 85%;
    top: 15%;
    height: 150px;
    align-content: center;
    transition: 0.3s;
    width: 40px;
    border-radius: 10px;
    background-color: rgb(39, 39, 43);
    box-shadow: 0px 1px 3px rgb(37, 37, 37);
}
.icon:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
}
.icon {
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.icon a {
    color: rgb(255, 255, 255);
    font-size: 22px;
    transition: 0.2s;
    text-decoration: none;
    justify-content: center;
    display: flex;
}

.icon a:hover {
    color: rgb(0, 0, 0);
}
#dsa-sec{
    margin-top: 15px;
    color: rgb(10, 10, 48);
    background-color: rgba(240, 248, 255, 0);
    justify-content: center;
    display: flex;
}
#dsa-sec button{
    background-color: rgba(255, 255, 255, 0);
    border: none;
    margin: 10px;
    font-size: 30px;
    cursor: pointer;
    transition: 0.5s;
}
#dsa-sec button:hover{
    color: rgb(105, 105, 146);
}
#explore-button{
    justify-content: center;
    display: flex;
    font-size: 15px;
}
#explore1{
    background-color:rgb(29, 18, 62) ;
    border: 2px solid black;
    color: rgb(255, 255, 255);
    padding: 10px 22px;
    transition: 0.4s;
    margin: 3px;
    border-radius: 25px;
}
#explore1:hover{
    color: aliceblue;
    background-color: rgb(0, 0, 0);
    border: 2px solid rgba(237, 237, 237, 0);
}
#explore2{
    margin: 5px;
    background-color:rgb(255, 255, 255) ;
    border: 1.5px solid black;
    color: rgb(0, 0, 0);
    padding: 10px 18px;
    transition: 0.4s;
    margin: 3px;
    border-radius: 25px;
}
#explore2:hover{
    color: rgb(20, 10, 42);
    background-color: rgba(229, 223, 255, 0.5);
    border: 1.5px solid rgba(255, 255, 255, 0);
}


@media only screen and (max-width: 600px) {

    #Home{
        display: inline-block;
    }
    .pic img{
        max-width: 280px;
    }
    #intro1{
        font-size: 30px;
        margin-top: -10px;
    }
    #intro3{
        font-size: 17px;
    }
    #intro3 span{
        font-weight: 800;
    }
    #intro2{
        margin: -30px 0px 0px 20px  ;
        font-size: 45px;
        width: 270px;
    }
    
    .icon{
        padding: 4px;
        position: absolute;
        left: 80%;
        top: 19%;
        height: 200px;
        align-content: center;
        width: 40px;
        gap: 10px;
    }
    .icon a{
        color: rgb(255, 255, 255);
        font-size: 25px;
        margin: 15px 0px;
    }
    #explore1{
        font-size: 15px;
    }
    #explore2{
        font-size: 15px;
    }
  }

  @keyframes typing {
    from {
      width: 0;
    }
  }